import ApiService from "../ApiService";
class ChannelsService extends ApiService {
  constructor() {
    super("/canal");
  }

  listChannels() {
    return this.get("");
  }

  getQrCode(sessionId) {
    return this.get("/qr/" + sessionId);
  }

  disconnectChannel(sessionId) {
    return this.delete("/" + sessionId);
  }

  verifyStatus(sessionId) {
    return this.get("/qr/status/" + sessionId);
  }

  createChannel(userId) {
    return this.post("", { userId: userId });
  }

  testChannel(phone, sessionId, key) {
    const body = JSON.stringify({
      phone: phone,
      messages: ["Teste disparo URA MBM"]
    });
  
    return this.postTest(
      "/enviar/" + sessionId,
      body,
      key,
    );
  }

  deleteChannel(sessionId){
    return this.delete("/deletar/"+sessionId)
  }
  
}
export default ChannelsService;
