import React, { useContext } from "react";
import "../css/ChannelCard.css";
import { UserContext } from "../UserContext";

function ChannelCard({
  togglePrep,
  setKey,
  chKey,
  phone,
  status,
  owner,
  sessionId,
  getQrCode,
  disconnectChannel,
  toggleTestChannelModal,
  setSessionTest,
  setSessionIdRequest,
  deleteChannel,
  submissions,
  setSequential,
}) {
  const statusName = status ? "Conectado" : "Desconectado";
  const thereIsOwner = owner && owner.trim() !== "";
  const thereIsPhone = status && phone && phone.trim() !== "";
  const { user } = useContext(UserContext);

  const toggleModal = () => {
    setKey(chKey);
    setSessionTest(sessionId);
    toggleTestChannelModal();
  };

  const prepModal = () => {
    setSequential(false);
    setKey(chKey);
    setSessionIdRequest(sessionId);
    togglePrep();
  };

  return (
    <div className="channel-card d-flex flex-column gap-20">
      {(user.roles.includes("ADMIN") || user.roles.includes("MANAGER")) && (
        <div className="dropdown-channel dropstart">
          <div
            className="options"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </div>

          <ul className="dropdown-menu">
            <li>
              <div
                className="dropdown-item"
                onClick={() => deleteChannel(sessionId)}
              >
                Excluir
              </div>
            </li>
          </ul>
        </div>
      )}
      <div className="channel-icon d-flex justify-content-center align-items-center">
        <i className="fa-brands fa-whatsapp channel-image" id="whatsapp"></i>
      </div>
      {thereIsPhone && (
        <div
          className="channel-phone d-flex justify-content-center align-items-center"
          style={{ marginBottom: "10px" }}
        >
          <h4>
            Telefone: <strong>{phone}</strong>
          </h4>
        </div>
      )}
      <div
        className="channel-status d-flex justify-content-center align-items-center"
        style={{ marginBottom: "10px" }}
      >
        <h4>
          Status: <strong>{statusName}</strong>
        </h4>
      </div>
      {thereIsOwner && (
        <div
          className="channel-owner d-flex justify-content-center align-items-center"
          style={{ marginBottom: "10px" }}
        >
          <h4>
            Responsável: <strong>{owner}</strong>
          </h4>
        </div>
      )}
      <div
        className="channel-submissions d-flex justify-content-center align-items-center"
        style={{ marginBottom: "10px" }}
      >
        <h4>
          Disparos: <strong>{submissions}</strong>
        </h4>
      </div>

      {status ? (
        <div
          className="d-flex justify-content-center button-container
       align-items-end"
        >
          <div
            className="d-flex justify-content-around"
            style={{ width: "100%" }}
          >
            <button
              className="btn btn-danger"
              onClick={() => disconnectChannel(sessionId)}
            >
              Desconectar
            </button>
            <button className="btn btn-success" onClick={toggleModal}>
              Testar
            </button>
            <button className="btn btn-primary" onClick={prepModal}>
              Gerar Requisição
            </button>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-around button-container
      align-items-end"
        >
          <button
            className="btn btn-success"
            onClick={() => getQrCode(sessionId)}
          >
            Conectar
          </button>
        </div>
      )}
    </div>
  );
}

export default ChannelCard;
