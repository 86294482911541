import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import CompanyService from "../app/service/CompanyService";

function PrepModal({
  show,
  addItem,
  message,
  setMessage,
  messages,
  togglePrep,
  toggleRequestModal,
  sequential,
  setCompanyKey,
}) {
  const [count, setCount] = useState(messages.length + 1);

  const companyService = new CompanyService();

  
  const getKey = () => {
    if(sequential && show){
      companyService.getCompanyKey().then(response => {
        setCompanyKey(response.data);
      })
    }
  }
    
  

  const handleAddItem = () => {
    addItem();
    setCount(count + 1);
  };

  const requestModal = () => {
    if (messages.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Insira pelo menos uma mensagem!",
        confirmButtonText: "OK",
      });
    } else {
      toggleRequestModal();
      togglePrep();
    }
  };

  return (
    <Modal
      show={show}
      onHide={togglePrep}
      size="lg"
      data-bs-theme="dark"
      className="modal-light"
      onShow={getKey}
    >
      <Modal.Header closeButton>
        <Modal.Title>Gerar Requisição</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formName">
            {messages.map((msg, index) => (
              <div key={index}>
                {index + 1}ª: {msg}
              </div>
            ))}
            <Form.Label>Mensagem</Form.Label>
            <textarea
              className="form-control"
              value={message}
              placeholder="Digite a mensagem"
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={requestModal}>
          Gerar Requisição
        </Button>
        <Button variant="primary" onClick={handleAddItem}>
          Adicionar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PrepModal;
