import ApiService from "../ApiService";
import jwtDecode from "jwt-decode";
class UsersService extends ApiService {
  constructor() {
    super("/usuario");
  }

  signIn(credentials) {
    return this.post("/login", credentials);
  }

  listUsersAdmin() {
    return this.get("");
  }

  isUserAuthenticated() {
    const token = localStorage.getItem("_authenticated_user");
    if (!token) {
      return false;
    }
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp && decoded.exp < currentTime) {
        return false;
      }
      return true;
    } catch (error) {
      console.error("Failed to decode token:", error);
      return false;
    }
  }

  createUser(userData) {
    return this.post("", userData);
  }

  editUser(userData, loginEdit) {
    return this.patch("/" + loginEdit, userData);
  }

  listUsersWithoutChannel() {
    return this.get("/vincular");
  }

  deleteUser(login) {
    return this.delete("/" + login);
  }

  getUser(login) {
    return this.get("/" + login);
  }

  changePassword(userData, login) {
    return this.patch("/senha/" + login, userData);
  }

  changeMyPassword(userData, login) {
    return this.patch("/minha-senha/" + login, userData);
  }
}

export default UsersService;
