import React, { useEffect, useState, useCallback } from "react";
import SideBar from "../component/SideBar";
import "../css/Dashboard.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SubmissionsTable from "../component/SubmissionsTable";
import DashboardService from "../app/service/DashboardService";
import { FormControl } from "react-bootstrap";

function useInterval(callback, delay) {
  const savedCallback = useCallback(callback, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, savedCallback]);
}

function Dashboard() {
  const dashboardService = new DashboardService();
  const [submissions, setSubmissions] = useState([]);
  const [filter, setFilter] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [data, setData] = useState({
    balance: 0,
    cost: 0,
    spends: 0,
    totalSubmissions: 0,
  });

  const areDatesValid = (startDate, endDate) => {
    const start = new Date(startDate + "T00:00:00Z"); // Adiciona a hora para garantir a comparação correta
    const end = new Date(endDate + "T00:00:00Z"); // Adiciona a hora para garantir a comparação correta

    const isStartValid = !isNaN(start.getTime());
    const isEndValid = !isNaN(end.getTime());

    const isStartBeforeEnd = start <= end;

    return isStartValid && isEndValid && isStartBeforeEnd;
  };

  const getData = () => {
    if (areDatesValid(startDate, endDate)) {
      getTableInfo();
    }
  };

  const getTableInfo = () => {
    dashboardService
      .getTableInfo(startDate, endDate)
      .then((response) => {
        const data = response.data.messages.map((item) => ({
          ...item,
          formattedDate: formatDateTime(item.date),
        }));
        setSubmissions(data);
        setFilter(data);
        setData(response.data.info);
      })
      .catch(() => {});
  };

  useInterval(getData, 30000);

  useEffect(() => {
    getData(); // Re-fetch data when startDate or endDate changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const formatDateTime = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length < 6) {
      return "Data inválida";
    }

    const [year, month, day, hour, minute, second] = dateArray;

    const date = new Date(year, month - 1, day, hour, minute, second);

    if (isNaN(date.getTime())) {
      return "Data inválida";
    }

    const dayFormatted = String(date.getDate()).padStart(2, "0");
    const monthFormatted = String(date.getMonth() + 1).padStart(2, "0");
    const yearFormatted = date.getFullYear();
    const hoursFormatted = String(date.getHours()).padStart(2, "0");
    const minutesFormatted = String(date.getMinutes()).padStart(2, "0");
    const secondsFormatted = String(date.getSeconds()).padStart(2, "0");

    return `${dayFormatted}/${monthFormatted}/${yearFormatted} ${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
  };

  return (
    <div id="channel-wrapper">
      <SideBar active={"dashboard"} />
      <div id="content">
        <div id="content-header">
          <h1>Dashboard</h1>
          <div className="period-container">
            <p>De</p>
            <FormControl
              type="date"
              data-bs-theme="dark"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <p>até</p>
            <FormControl
              type="date"
              data-bs-theme="dark"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-around">
          <div
            id="info-card"
            className="d-flex justify-content-around align-items-center p-3 rounded bg-dark"
          >
            <h5>Saldo: R${data.balance.toFixed(2).replace(".", ",")}</h5>
            <h5>
              Custo por disparo: R${data.cost.toFixed(2).replace(".", ",")}
            </h5>
            <h5>Gastos: R${data.spends.toFixed(2).replace(".", ",")}</h5>
            <h5>Disparos: {data.totalSubmissions}</h5>
          </div>
        </div>
        <div id="table-container">
          <SubmissionsTable
            submissions={submissions}
            filter={filter}
            setFilter={setFilter}
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
