import React from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import Users from "./view/Users";
import Channels from "./view/Channels";
import NotFound from "./view/NotFound";
import Login from "./view/Login";
import UsersService from "./app/service/UsersService";
import Dashboard from "./view/Dashboard";
import ControlPanel from "./view/ControlPanel";

const usersService = new UsersService();

function AuthenticatedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        usersService.isUserAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <AuthenticatedRoute path="/usuarios" component={Users} />
        <AuthenticatedRoute path="/canais" component={Channels} />
        <AuthenticatedRoute path="/dashboard" component={Dashboard} />
        <AuthenticatedRoute path="/controlPanel" component={ControlPanel} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
