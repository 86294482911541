import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

function ChangeCostModal({
  show,
  newCost,
  setNewCost,
  toggleModal,
  handleChangeCost,
}) {
  return (
    <Modal show={show} onHide={toggleModal}>
      <Modal.Header closeButton>
        <h4>Custo</h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            type="text"
            value={newCost}
            onChange={(e) => setNewCost(e.target.value)}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleChangeCost()}>Salvar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ChangeCostModal;
