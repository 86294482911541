import React from "react";
import SideBarMenu from "./SideBarMenu";
import SideBarUser from "./SideBarUser";
import "../css/SideBar.css";

import Logo from "../assets/images/logoClear.png";

function SideBar({ active }) {
  return (
    <>
      <div
        id="sidebar"
        style={{
          width: "280px",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
        }}
        className={`d-flex flex-column flex-shrink-0 p-3`}
      >
        <a
          href="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none"
        >
          <img className="bi me-4" width="40" src={Logo} alt="Logo" />
          <span className="fs-4">Ura WhatsApp</span>
        </a>
        <hr />
        <SideBarMenu active={active} />
        <hr />
        <SideBarUser />
      </div>
      {/* 
      <button
        className={`btn btn-secondary toggle-btn ${
          isNavbarVisible === true
            ? "show-button"
            : isNavbarVisible === false
            ? "hide-button"
            : ""
        }`}
        onClick={toggleNavbar}
      >
        {isNavbarVisible ? (
          <i className="fa-solid fa-arrow-right"></i>
        ) : (
          <i className="fa-solid fa-arrow-left"></i>
        )}
      </button>
      */}
    </>
  );
}

export default SideBar;
