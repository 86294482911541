import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import UsersServices from "../app/service/UsersService";
import Swal from "sweetalert2";

const CreateUserModal = ({
  loginEdit,
  show,
  toggleModal,
  listUsers,
  edit,
  setEdit,
}) => {
  const [name, setName] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(""); // Valor padrão
  const usersServices = new UsersServices();

  useEffect(() => {
    if (edit) {
      getUser();
    } else {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, loginEdit]);

  const getUser = () => {
    usersServices
      .getUser(loginEdit)
      .then((response) => {
        setName(response.data.name);
        setLogin(response.data.login);
        setRole(response.data.roles[0]);
      })
      .catch(() => console.log("Erro ao buscar usuário"));
  };

  const resetForm = () => {
    setName("");
    setLogin("");
    setRole("");
    setPassword("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Preencha o nome do usuário",
        confirmButtonText: "OK",
      });
    } else if (login === "") {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Preencha o login do usuário",
        confirmButtonText: "OK",
      });
    } else if (password === "" && !edit) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Preencha a senha do usuário",
        confirmButtonText: "OK",
      });
    } else if (role === "") {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Selecione a função do usuário",
        confirmButtonText: "OK",
      });
    } else {
      if (edit) {
        editUser();
      } else {
        createUser();
      }
    }
  };

  const editUser = () => {
    const userData = {
      name,
      login,
      roles: [role],
    };

    usersServices
      .editUser(userData, loginEdit)
      .then(() => {
        setEdit(false);
        listUsers();
        toggleModal();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const createUser = () => {
    const userData = {
      name,
      login,
      password,
      roles: [role],
    };

    usersServices
      .createUser(userData)
      .then(() => {
        listUsers();
        toggleModal();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleError = (error) => {
    if (error.response?.status === 400) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: error.response.data.error,
        confirmButtonText: "OK",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Entre em contato com o suporte!",
        confirmButtonText: "OK",
      });
    }
  };

  const handleModalClose = () => {
    if (edit) {
      setEdit(false);
    }
    toggleModal();
  };

  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      size="lg"
      data-bs-theme="dark"
      className="modal-light"
    >
      <Modal.Header closeButton>
        <Modal.Title>{edit ? "Editar Usuário" : "Criar Usuário"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col">
              <Form.Group controlId="formName">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group controlId="formLogin">
                <Form.Label>Login</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o login"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                  required
                />
              </Form.Group>
            </div>
          </div>
          <div className="row" style={{ marginTop: "10px" }}>
            {!edit && (
              <div className="col">
                <Form.Group controlId="formPassword">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Digite a senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            )}
            <div className="col">
              <Form.Group controlId="formRole">
                <Form.Label>Função</Form.Label>
                <Form.Select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                  id="role-select"
                >
                  <option value="">Selecione uma opção</option>
                  <option value="USER">Atendente</option>
                  <option value="MANAGER">Supervisor</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateUserModal;
