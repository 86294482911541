import ApiService from "../ApiService";
class CompanyService extends ApiService {
  constructor() {
    super("/empresa");
  }

  getControlPanelData() {
    return this.get("");
  }

  toggleStatus(id) {
    return this.patch("/status/" + id);
  }

  createCompany(data) {
    return this.post("", data);
  }

  signInAsAdmin(id) {
    return this.get("/login/" + id);
  }

  changeBalance(id,value){
    return this.patch("/saldo/" + id, {
      value: value
    })
  }

  changeCost(id,value){
    return this.patch("/custo/" + id, {
      value: value
    })
  }

  getCompanyKey(){
    return this.get("/chave");
  }
}

export default CompanyService;
