import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import UsersService from "../app/service/UsersService";
import { useUser } from "../UserContext";
import jwtDecode from "jwt-decode";
import Logo from "../assets/images/logo.png";
import "../css/Login.css";
import { UserContext } from "../UserContext";

const Login = () => {
  const history = useHistory();
  const { setUser } = useUser();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const service = new UsersService();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (service.isUserAuthenticated() && user) {
      if (user.roles.includes("USER")) {
        history.push("/canais");
      } else if (
        user.roles.includes("ADMIN") ||
        user.roles.includes("MANAGER")
      ) {
        history.push("/dashboard");
      } else if (user.roles.includes("MBM")) {
        history.push("/controlPanel");
      }
    }
  }, [history, service, user]);

  const signIn = useCallback(
    async (e) => {
      e.preventDefault();
      if (!login || !password) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Campos usuário e senha não podem estar vazios",
        });
        return;
      }

      setLoading(true);
      try {
        const response = await service.signIn({ login, password });
        const token = response.data;
        localStorage.setItem("_authenticated_user", token);
        const decodedUser = decodeJWT(token);
        setUser(decodedUser);
        setLoading(false);
        if (decodedUser.roles.includes("USER")) {
          history.push("/canais");
        } else if (decodedUser.roles.includes("ADMIN")) {
          history.push("/dashboard");
        }
      } catch (error) {
        setLoading(false);
        const errorMessage =
          error.response?.status === 400
            ? error.response.data.error
            : "Entre em contato com o suporte!";
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: errorMessage,
        });
      }
    },
    [login, password, service, history, setUser]
  );

  const decodeJWT = (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error("Failed to decode JWT:", error);
      return null;
    }
  };

  return (
    <div id="body-login">
      <div className="img">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="content-login">
        <div className="form">
          <form onSubmit={signIn}>
            <div className="imgLogo">
              <img src={Logo} alt="Logo" />
            </div>
            <input
              placeholder="Login"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              type="text"
              id="login"
              name="login"
            />
            <input
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              id="password"
              name="password"
            />
            <button className="submit" type="submit" disabled={loading}>
              {loading ? "Entrando..." : "Enviar"}
            </button>
          </form>
        </div>
      </div>

      <style jsx="true">{`
        #toggleTheme,
        #sun,
        #moon {
          display: none;
        }
      `}</style>
    </div>
  );
};

export default Login;
