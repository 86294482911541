import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
const TestChannelModal = ({
  testChannel,
  showTestChannelModal,
  toggleTestChannelModal,
  setPhone,
  phone,
  listChannels,
}) => {
  const toggleModal = () => {
    if (phone.length > 13 || phone.length < 12 || !phone.startsWith("55")) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Número fora do padrão",
        confirmButtonText: "OK",
      });
    } else {
      toggleTestChannelModal();
      testChannel();
      listChannels();
    }
  };
  return (
    <Modal
      show={showTestChannelModal}
      onHide={toggleTestChannelModal}
      size="lg"
      data-bs-theme="dark"
      className="modal-light"
    >
      <Modal.Header closeButton>
        <Modal.Title>Enviar mensagem</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={toggleModal}>
          <Form.Group controlId="formName">
            <Form.Label>
              Número WhatsApp <strong>(55 + DDD + Número)</strong>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o número"
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={toggleModal}>
          Enviar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestChannelModal;
