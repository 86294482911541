import axios from "axios";

const httpClient = axios.create({
  baseURL: "https://api.mbmchat.com.br",
});

class ApiService {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;
  }

  getAuthHeader() {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("_authenticated_user")}`
      },
    };
  }

  getAuthHeaderTest(key) {
    return {
      headers: {
        Authorization: key,
        "Content-Type": "application/json"
      },
    };
  }

  signIn(url, object) {
    const requestUrl = `${this.apiUrl}${url}`;
    return httpClient.post(requestUrl, object);
  }

  post(url, object) {
    const requestUrl = `${this.apiUrl}${url}`;
    return httpClient.post(requestUrl, object, this.getAuthHeader());
  }

  put(url, object) {
    const requestUrl = `${this.apiUrl}${url}`;
    return httpClient.put(requestUrl, object, this.getAuthHeader());
  }

  patch(url, object) {
    const requestUrl = `${this.apiUrl}${url}`;
    return httpClient.patch(requestUrl, object, this.getAuthHeader());
  }

  delete(url) {
    const requestUrl = `${this.apiUrl}${url}`;
    return httpClient.delete(requestUrl, this.getAuthHeader());
  }

  get(url) {
    const requestUrl = `${this.apiUrl}${url}`;
    return httpClient.get(requestUrl, this.getAuthHeader());
  }

  postTest(url, object, key) {
    const requestUrl = `${this.apiUrl}${url}`;
    return httpClient.post(requestUrl, object, this.getAuthHeaderTest(key));
  }
}

export default ApiService;
