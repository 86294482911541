import React, { useEffect, useState } from "react";
import "../css/SubmissionsTable.css";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";
import ChangeBalanceModal from "./ChangeBalanceModal";
import ChangeCostModal from "./ChangeCostModal";


function ClientsTable({
  companies,
  filter,
  setFilter,
  toggleCompanyStatus,
  signInAsAdmin,
  changeBalance,
  changeCost
}) {
  const [search, setSearch] = useState("");
  const [newBalance, setNewBalance] = useState("");
  const [id, setId] = useState("");
  const [show, setShow] = useState(false);
  const [showChangeCostModal, setShowChangeCostModal] = useState(false);
  const [newCost, setNewCost] = useState("");

  const openChangeBalanceModal = (id, value) => {
    setNewBalance(value.toFixed(2).replace(".", ","));
    setId(id);
    toggleModal();
  };

  const handleChangeBalance = () => {
    const valueString = newBalance.replace(",", ".");
    const value = parseFloat(valueString);
    changeBalance(id, value);
    toggleModal();
  };
  
  const openChangeCostModal = (id, value) => {
    setNewCost(value.toFixed(2).replace(".", ","));
    setId(id);
    toggleChangeCostModal();
  };

  const handleChangeCost = () => {
    const valueString = newCost.replace(",", ".");
    const value = parseFloat(valueString);
    changeCost(id, value);
    toggleChangeCostModal();
  };


  const columns = [
    {
      name: "Razão social",
      selector: (row) => row.legalName,
      sortable: true,
    },
    {
      name: "Nome fantasia",
      selector: (row) => row.tradeName,
      sortable: true,
    },
    {
      name: "CPF/CNPJ",
      selector: (row) => row.cnpjCpf,
      sortable: true,
    },
    {
      name: "Data de cadastro",
      selector: (row) => row.formattedDate,
      sortable: true,
    },
    {
      name: "Saldo",
      selector: (row) => (
        <div
          className="balance"
          onClick={() => openChangeBalanceModal(row.id, row.balance)}
        >
          {"R$ " + row.balance.toFixed(2).replaceAll(".", ",")}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Custo",
      selector: (row) => (
        <div
          className="send-cost"
          onClick={() => openChangeCostModal(row.id, row.sendCost)}
        >
          {"R$ " + row.sendCost.toFixed(2).replaceAll(".", ",")}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Número de disparos",
      selector: (row) => row.submissions,
      sortable: true,
    },
    {
      name: "Canais conectados",
      selector: (row) => row.connectedChannels,
      sortable: true,
    },
    {
      name: "Entrar como gestor",
      selector: (row) => (
        <i
          className="fa-solid fa-user admin-button"
          style={{ fontSize: "30px" }}
          onClick={() => {
            signInAsAdmin(row.id);
          }}
        ></i>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <Button
          onClick={() => toggleCompanyStatus(row.id)}
          variant={row.status ? "success" : "danger"}
        >
          {" "}
          {row.status ? (
            <i className="fa-solid fa-check"></i>
          ) : (
            <i className="fa-solid fa-xmark"></i>
          )}
        </Button>
      ),
      sortable: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Itens por página",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  useEffect(() => {
    const result = companies.filter((item) => {
      const searchLower = search.toLowerCase();
      const legalName = item.legalName.toLowerCase();
      const tradeName = item.tradeName.toLowerCase();
      const cnpjCpf = item.cnpjCpf;
      const formattedDate = item.formattedDate;
      const formattedDateWithoutSlash = item.formattedDate.replaceAll("/", "");
      return (
        legalName.includes(searchLower) ||
        tradeName.includes(searchLower) ||
        cnpjCpf.includes(search) ||
        formattedDate.includes(search) ||
        formattedDateWithoutSlash.includes(search)
      );
    });
    setFilter(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const CustomNoDataComponent = () => (
    <div className="noData">
      <p>Nenhum dado encontrado!</p>
    </div>
  );

  const toggleChangeCostModal = () => {
    setShowChangeCostModal((prev) => !prev);
  };

  const toggleModal = () => {
    setShow((prev) => !prev);
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={filter}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        fixedHeader
        subHeader
        subHeaderComponent={
          <div className="table-header-container d-flex justify-content-between">
            <h4>Clientes</h4>
            <input
              type="text"
              className="form-control table-search"
              data-bs-theme="dark"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder="Pesquisar"
            />
          </div>
        }
        noDataComponent={<CustomNoDataComponent />}
      />
      <ChangeBalanceModal
        show={show}
        newBalance={newBalance}
        setNewBalance={setNewBalance}
        toggleModal={toggleModal}
        handleChangeBalance={handleChangeBalance}
      />
      <ChangeCostModal
        show={showChangeCostModal}
        newCost={newCost}
        setNewCost={setNewCost}
        toggleModal={toggleChangeCostModal}
        handleChangeCost={handleChangeCost}
      />
    </>
  );
}

export default ClientsTable;
