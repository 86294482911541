import React, { useContext, useState } from "react";
import "../css/UserCard.css";
import { UserContext } from "../UserContext";
import Swal from "sweetalert2";
import ChangePasswordModal from "./ChangePasswordModal";

function UserCard({
  username,
  channel,
  name,
  deleteUser,
  toggleModal,
  setEdit,
  setLoginEdit,
}) {
  if (channel === null || channel === "") {
    channel = "Não vinculado";
  }
  const { user } = useContext(UserContext);
  const [showCpModal, setShowCpModal] = useState(false);
  const [adminChange, setAdminChange] = useState(false);

  const toggleCpModal = () => {
    setAdminChange((prev) => !prev);
    setShowCpModal((prev) => !prev);
  };

  const callDeleteUser = () => {
    if (channel === "Vinculado") {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Não é possível excluir um usuário vinculado a um canal, exclua o canal antes de excluir o usuário!",
        confirmButtonText: "OK",
      });
    } else {
      deleteUser(username);
    }
  };

  const handleDelete = () => {
    Swal.fire({
      icon: "warning",
      title: "Tem certeza que deseja excluir o usuário?",
      showDenyButton: true,
      confirmButtonText: "Sim",
      denyButtonText: `Não`,
    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteUser();
      }
    });
  };

  const handleEdit = () => {
    setLoginEdit(username);
    setEdit(true);
    toggleModal();
  };

  return (
    <div className="user-card d-flex flex-column gap-20">
      {(user.roles.includes("ADMIN") || user.roles.includes("MANAGER")) && (
        <div className="dropdown-user dropstart">
          <div
            className="options"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </div>

          <ul className="dropdown-menu">
            <li>
              <div className="dropdown-item" onClick={() => handleEdit()}>
                Editar
              </div>
            </li>
            <li>
              <div className="dropdown-item" onClick={() => toggleCpModal()}>
                Alterar senha
              </div>
            </li>
            <li>
              <div className="dropdown-item" onClick={() => handleDelete()}>
                Excluir
              </div>
            </li>
          </ul>
        </div>
      )}
      <div className="user-icon d-flex justify-content-center align-items-center">
        <i className="fa-solid fa-user user-image"></i>
      </div>
      <div className="user-name d-flex justify-content-center align-items-center">
        <h4>
          Nome: <strong>{name}</strong>
        </h4>
      </div>
      <div className="user-name d-flex justify-content-center align-items-center">
        <h4>
          Usuário: <strong>{username}</strong>
        </h4>
      </div>
      <div className="user-channel d-flex justify-content-center align-items-center">
        <h4>
          Canal: <strong>{channel}</strong>
        </h4>
      </div>
      <ChangePasswordModal
        show={showCpModal}
        toggle={toggleCpModal}
        login={username}
        adminChange={adminChange}
      />
    </div>
  );
}

export default UserCard;
