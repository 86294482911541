import React, { useRef } from "react";
import { Modal, Button } from "react-bootstrap";

function RequestModal({
  show,
  messages,
  chKey,
  toggleRequestModal,
  sessionIdRequest,
  sequential,
  companyKey,
}) {
  const bodyRef = useRef(null);
  const apiUrlRef = useRef(null);
  const headerRef = useRef(null);

  const jsonContent = JSON.stringify(
    {
      phone: "%%destino%%",
      messages: messages,
      campaign: "%%campanha_id%%",
    },
    null,
    2
  );

  function handleCopy(contentRef) {
    if (contentRef.current) {
      const range = document.createRange();
      range.selectNode(contentRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      navigator.clipboard
        .writeText(
          contentRef.current.innerText || contentRef.current.textContent
        );

      window.getSelection().removeAllRanges();
    }
  }

  return (
    <Modal
      show={show}
      onHide={toggleRequestModal}
      size="lg"
      data-bs-theme="dark"
      className="modal-light"
    >
      <Modal.Header closeButton>
        <Modal.Title>Requisição</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
        <h1>
          Link API
          <Button className="mb-2 ms-3" onClick={() => handleCopy(apiUrlRef)}>
            <i className="fa-solid fa-copy"></i>
          </Button>
        </h1>
        <div>
          <p>
            <strong ref={apiUrlRef}>
              {sequential
                ? `https://api.mbmchat.com.br/disparo`
                : `https://api.mbmchat.com.br/canal/enviar/${sessionIdRequest}`}
            </strong>
          </p>
        </div>
        <h1>
          Body
          <Button className="mb-2 ms-3" onClick={() => handleCopy(bodyRef)}>
            <i className="fa-solid fa-copy"></i>
          </Button>
        </h1>
        <div ref={bodyRef}>
          <pre>
            <code>{jsonContent}</code>
          </pre>
        </div>
        <h1>
          Header
          <Button className="mb-2 ms-3" onClick={() => handleCopy(headerRef)}>
            <i className="fa-solid fa-copy"></i>
          </Button>
        </h1>
        <div>
          <p>
            <strong ref={headerRef}>
            Authorization: {sequential  ? companyKey : chKey}
            </strong>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default RequestModal;
