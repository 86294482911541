import ApiService from "../ApiService";
class DashboardService extends ApiService {
    constructor() {
        super("/dashboard");
    }

    getDashInfo() {
        return this.get("");
    }

    getTableInfo(startDate, endDate){
        return this.get("/table/"+startDate+"/"+endDate);
    }

}

export default DashboardService;
