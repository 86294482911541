import React, { createContext, useState, useEffect } from "react";
import jwtDecode from "jwt-decode";

// Criação do contexto
const UserContext = createContext();

// Provedor do contexto
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Tenta carregar o usuário do localStorage
    const token = localStorage.getItem("_authenticated_user");
    if (token) {
      const decodedUser = decodeJWT(token);
      setUser(decodedUser);
    }
  }, []);

  // Função para decodificar o token JWT
  const decodeJWT = (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error("Erro ao decodificar o token JWT:", error);
      return null;
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook customizado para usar o contexto
export const useUser = () => React.useContext(UserContext);

// Exportar o contexto para uso em componentes de classe
export { UserContext };
