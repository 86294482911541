import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./css/App.css";
import Rotas from "./Routes";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    const isDarkMode = localStorage.getItem("isDarkMode") === "true";
    this.state = { isDarkMode };
  }

  handleButtonClick = () => {
    this.setState((prevState) => {
      const newIsDarkMode = !prevState.isDarkMode;
      localStorage.setItem("isDarkMode", newIsDarkMode);
      return { isDarkMode: newIsDarkMode };
    });
  };

  render() {
    const { isDarkMode } = this.state;
    const themeStylePath = isDarkMode
      ? "./css/AppDark.css"
      : "./css/AppLight.css";
    return (
      <div>
        <link
          id="theme-link"
          rel="stylesheet"
          type="text/css"
          href={themeStylePath}
        />
        {isDarkMode && (
          <link
            id="theme-link"
            rel="stylesheet"
            type="text/css"
            href="./css/dark.css"
          />
        )}
        <label className="switch" id="toggleTheme">
          <input
            type="checkbox"
            checked={isDarkMode}
            onChange={this.handleButtonClick}
          />
          <span className="slider round"></span>
        </label>
        <Rotas />
        <i
          className="fa-solid fa-moon"
          id="moon"
          onClick={this.handleButtonClick}
        ></i>
        <i
          className="fa-solid fa-sun"
          id="sun"
          onClick={this.handleButtonClick}
        ></i>
      </div>
    );
  }
}
