import React, { useEffect, useState, useContext } from "react";
import "../css/ControlPanel.css";
import CompanyService from "../app/service/CompanyService";
import ClientsTable from "../component/ClientesTable";
import { Button } from "react-bootstrap";
import CompanyModal from "../component/CompanyModal";
import Swal from "sweetalert2";
import { UserContext } from "../UserContext";
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";

function ControlPanel() {
  const [companies, setCompanies] = useState([]);
  const [filter, setFilter] = useState([]);
  const [show, setShow] = useState(false);
  const [createCompanyData, setCreateCompanyData] = useState({
    tradeName: "",
    legalName: "",
    cnpjCpf: "",
    sendCost: "",
    balance: "",
    login: "",
    password: "",
    name: "",
    dazId: ""
  });

  const companyService = new CompanyService();
  const { setUser } = useContext(UserContext);
  const history = useHistory(); // Initialize useHistory

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    companyService.getControlPanelData().then((response) => {
      const data = response.data.map((item) => ({
        ...item,
        formattedDate: formatDate(item.date),
      }));
      setCompanies(data);
      setFilter(data);
    });
  };

  const toggleCompanyStatus = (id) => {
    companyService.toggleStatus(id).then(() => getData());
  };

  const decodeJWT = (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error("Failed to decode JWT:", error);
      return null;
    }
  };
  const signInAsAdmin = (id) => {
    companyService.signInAsAdmin(id).then((response) => {
      localStorage.removeItem("_authenticated_user");
      setUser(null);
      localStorage.setItem("_authenticated_user", response.data);
      const decodedUser = decodeJWT(response.data);
      setUser(decodedUser);
      history.push("/dashboard"); // Navigate using history
    });
  };

  const createCompany = () => {
    companyService
      .createCompany(createCompanyData)
      .then(() => {
        setShow(false);
        getData();
      })
      .catch(handleError);
  };

  const handleError = (error) => {
    const message =
      error.response.status === 400
        ? error.response.data.error
        : "Entre em contato com o suporte!";

    Swal.fire({
      icon: "error",
      title: "Erro",
      text: message,
      confirmButtonText: "OK",
    });
  };

  const formatDate = (dateArray) => {
    if (!Array.isArray(dateArray)) {
      return "Data inválida";
    }

    const [year, month, day] = dateArray;

    const date = new Date(year, month - 1, day);

    if (isNaN(date.getTime())) {
      return "Data inválida";
    }

    const dayFormatted = String(date.getDate()).padStart(2, "0");
    const monthFormatted = String(date.getMonth() + 1).padStart(2, "0");
    const yearFormatted = date.getFullYear();

    return `${dayFormatted}/${monthFormatted}/${yearFormatted}`;
  };

  const toggleModal = () => {
    setShow((prev) => !prev);
  };

  const changeBalance = (id, newBalance) => {
    companyService.changeBalance(id, newBalance).then(() => {
      getData();
    });
  };

  const changeCost = (id, newCost) => {
    companyService.changeCost(id, newCost).then(() => {
      getData();
    });
  };

  return (
    <div id="control-panel-container">
      <h1>Painel de controle</h1>
      <div className="d-flex justify-content-end" style={{ width: "100%" }}>
        <Button variant="success" className="mt-3 mb-3" onClick={toggleModal}>
          Cadastrar empresa
        </Button>
      </div>
      <div id="control-panel-body">
        <ClientsTable
          companies={companies}
          filter={filter}
          setFilter={setFilter}
          toggleCompanyStatus={toggleCompanyStatus}
          signInAsAdmin={signInAsAdmin}
          changeBalance={changeBalance}
          changeCost={changeCost}
        />
      </div>
      <CompanyModal
        show={show}
        toggleModal={toggleModal}
        createCompanyData={createCompanyData}
        setCreateCompanyData={setCreateCompanyData}
        createCompany={createCompany}
      />
    </div>
  );
}

export default ControlPanel;
