import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom"; // Corrija a importação de useHistory
import "../css/SideBarUser.css";
import { UserContext } from "../UserContext";
import ChangePasswordModal from "./ChangePasswordModal";

const SideBarUser = () => {
  const { user, setUser } = useContext(UserContext);
  const [showCpModal, setShowCpModal] = useState(false);
  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("_authenticated_user");
    setUser(null);
    history.push("/");
  };

  const toggleCpModal = () => {
    setShowCpModal((prev) => !prev);
  };

  if (!user) {
    return <div>Carregando...</div>; // Exibe uma mensagem ou spinner enquanto o usuário não está carregado
  }

  const userName = user.name ? user.name.split(" ")[0] : "Carregando...";

  return (
    <>
      <div className="dropdown">
        <div
          className="d-flex align-items-center text-decoration-none dropdown-toggle"
          id="dropdownUser1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i
            className="fa-solid fa-user rounded-circle me-3"
            style={{ width: "25px", height: "25px", fontSize: "25px" }}
          ></i>
          <strong>{userName}</strong>
        </div>
        <ul
          className="dropdown-menu text-small shadow"
          aria-labelledby="dropdownUser1"
        >
          <li>
            <div className="dropdown-item" onClick={toggleCpModal}>
              Mudar senha
            </div>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <div id="logout" className="dropdown-item" onClick={logout}>
              Sair
            </div>
          </li>
        </ul>
      </div>
      <ChangePasswordModal
        show={showCpModal}
        toggle={toggleCpModal}
        login={user.sub}
        adminChange={false}
      />
    </>
  );
};

export default SideBarUser;
