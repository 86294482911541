import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import ChannelsService from "../app/service/ChannelsService";
import UsersService from "../app/service/UsersService";

const CreateChannelModal = ({ showModal, toggleModal, listChannels }) => {
  const [userWithoutCompanyId, setUserWithoutCompanyId] = useState("");
  const channelsService = new ChannelsService();
  const usersService = new UsersService();
  const [usersWithoutChannel, setUsersWithoutChannel] = useState([]);

  const loadUser = () => {
    usersService
      .listUsersWithoutChannel()
      .then((response) => setUsersWithoutChannel(response.data))
      .catch((error) => {
        console.error("Error fetching users:", error);
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Falha ao carregar usuários",
          confirmButtonText: "OK",
        });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userWithoutCompanyId === "") {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Selecione o usuário",
        confirmButtonText: "OK",
      });
    } else {
      createChannel();
    }
  };

  const createChannel = () => {
    channelsService
      .createChannel(userWithoutCompanyId)
      .then(() => {
        listChannels();
        toggleModal();
      })
      .catch((error) => {
        if (error.response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: error.response.data.error,
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: "Entre em contato com o suporte!",
            confirmButtonText: "OK",
          });
        }
      });
  };

  return (
    <Modal
      show={showModal}
      onHide={toggleModal}
      size="lg"
      data-bs-theme="dark"
      className="modal-light"
      onShow={loadUser}
    >
      <Modal.Header closeButton>
        <Modal.Title>Criar Canal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formRole">
            <Form.Label>Usuário</Form.Label>
            <Form.Select
              value={userWithoutCompanyId}
              onChange={(e) => {
                e.stopPropagation();
                setUserWithoutCompanyId(e.target.value);
              }}
              required
            >
              <option value="">Selecione um usuário</option>
              {usersWithoutChannel.map((userWithoutChannel) => (
                <option
                  key={userWithoutChannel.id}
                  value={userWithoutChannel.id}
                >
                  {userWithoutChannel.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateChannelModal;
