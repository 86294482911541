import React, { useState, useContext, useEffect } from "react";
import SideBar from "../component/SideBar";
import "../css/Channels.css";
import ChannelCard from "../component/ChannelCard";
import ChannelsService from "../app/service/ChannelsService";
import { UserContext } from "../UserContext";
import "bootstrap/dist/css/bootstrap.min.css";
import ChannelModal from "../component/ChannelModal";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import CreateChannelModal from "../component/CreateChannelModal";
import TestChannelModal from "../component/TestChannelModal";
import PrepModal from "../component/PrepModal";
import RequestModal from "../component/RequestModal";

function Channels() {
  const [show, setShow] = useState(false);
  const [channels, setChannels] = useState([]);
  const [qrCode, setQrCode] = useState("");
  const [attempts, setAttempts] = useState(0);
  const maxAttempts = 5;
  const { user } = useContext(UserContext);
  const channelsService = new ChannelsService();
  const [modalSessionId, setModalSessionId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTestChannelModal, setShowTestChannelModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [sessionTest, setSessionTest] = useState("");
  const [key, setKey] = useState("");
  const [showPrep, setShowPrep] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [sessionIdRequest, setSessionIdRequest] = useState("");
  const [sequential, setSequential] = useState(null)

  const deleteChannel = (sessionId) => {
    channelsService.deleteChannel(sessionId).then(() => {
      listChannels();
    });
  };

  const toggleRequestModal = () => {
    if (showRequestModal) setMessages([]);

    setShowRequestModal((prevShowRequestModal) => !prevShowRequestModal);
  };

  const togglePrep = () => {
    setShowPrep((prevShowPrep) => !prevShowPrep);
  };

  const addItem = () => {
    setMessages([...messages, message]);
    setMessage("");
  };

  const toggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const toggleTestChannelModal = () => {
    setShowTestChannelModal((prevShowTestModal) => !prevShowTestModal);
  };
  useEffect(() => {
    listChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let statusInterval;
    let qrCodeInterval;

    if (isModalOpen) {
      statusInterval = setInterval(async () => {
        try {
          const statusResponse = await channelsService.verifyStatus(
            modalSessionId
          );
          if (statusResponse.data) {
            handleClose();
          }
        } catch (error) {}
      }, 10000);

      qrCodeInterval = setInterval(async () => {
        try {
          const qrCodeResponse = await channelsService.getQrCode(
            modalSessionId
          );
          if (qrCodeResponse.data !== "") {
            setQrCode(`data:image/png;base64,${qrCodeResponse.data}`);
          } else {
            if (attempts < maxAttempts) {
              setAttempts((prevAttempts) => prevAttempts + 1);
            }
          }
        } catch (error) {}
      }, 40000);

      return () => {
        clearInterval(statusInterval);
        clearInterval(qrCodeInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, modalSessionId, attempts]);

  const listChannels = () => {
    channelsService
      .listChannels()
      .then((response) => {
        setChannels(response.data);
      })
      .catch((error) => {});
  };

  const fetchQrCode = (sessionId) => {
    setModalSessionId(sessionId);

    const fetchAttempts = (attempts) => {
      channelsService
        .getQrCode(sessionId)
        .then((response) => {
          if (response.data === "") {
            if (attempts < maxAttempts) {
              setAttempts(attempts + 1);
              setTimeout(() => fetchAttempts(attempts + 1), 2000);
            } else {
              console.error("Número máximo de tentativas atingido.");
            }
          } else {
            setQrCode(`data:image/png;base64,${response.data}`);
            handleShow();
            setAttempts(0);
          }
        })
        .catch((error) => {
          console.error("Erro ao obter QR code:", error);
        });
    };

    setAttempts(0);
    setQrCode("");
    fetchAttempts(0);
  };

  const handleClose = () => {
    setShow(false);
    setIsModalOpen(false);
    setQrCode("");
    setAttempts(0);
    listChannels();
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleShow = () => setShow(true);

  const disconnectChannel = (sessionId) => {
    channelsService
      .disconnectChannel(sessionId)
      .then(() => listChannels())
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Esse canal já foi desconectado. Pressione OK para atualizar a página!",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            listChannels();
          }
        });
      });
  };

  const testChannel = () => {
    channelsService
      .testChannel(phone, sessionTest, key)
      .then()
      .catch((error) => {
        if (error.response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: error.response.data.error,
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: "Entre em contato com o suporte!",
            confirmButtonText: "OK",
          });
        }
      });
  };

  const [companyKey, setCompanyKey] = useState("");


  const handleSequentialRequest = () =>{
    setSequential(true);
    togglePrep();
  }

  return (
    <div id="channel-wrapper">
      <SideBar active={"channels"} />
      <div id="content">
        <div id="content-header">
          {user && user.roles.includes("USER") ? (
            <h1>Meu Canal</h1>
          ) : (<>
          <h1>Canais</h1>
            <Button className="sequentialButton"
          onClick={handleSequentialRequest}
          >Gerar Requisição Sequencial</Button></>
            
          )}
          
        </div>
        <div className="d-flex justify-content-center">
          <div
            id="content-body"
            className="d-flex flex-wrap justify-content-left"
            style={{ gap: "40px", maxWidth: "1430px" }}
          >
            {channels.map((channel) => (
              <ChannelCard
                key={channel.sessionId}
                phone={channel.phone}
                status={channel.status}
                owner={
                  user.roles &&
                  (user.roles.includes("ADMIN") ||
                    user.roles.includes("MANAGER"))
                    ? channel.owner
                    : ""
                }
                sessionId={channel.sessionId}
                getQrCode={fetchQrCode}
                disconnectChannel={disconnectChannel}
                toggleTestChannelModal={toggleTestChannelModal}
                setPhone={setPhone}
                setSessionTest={setSessionTest}
                chKey={channel.key}
                setKey={setKey}
                togglePrep={togglePrep}
                setSequential={setSequential}
                sequential={sequential}
                setSessionIdRequest={setSessionIdRequest}
                deleteChannel={deleteChannel}
                submissions={channel.submissions}
              />
            ))}
          </div>
        </div>
      </div>
      <ChannelModal
        handleClose={handleClose}
        show={show}
        qrCode={qrCode}
        Modal={Modal}
        handleOpen={handleOpen}
      />
      {user && !user.roles.includes("USER") && (
        <div
          onClick={toggleModal}
          id="create-user"
          className="d-flex justify-content-center align-items-center"
        >
          <i className="fa-solid fa-plus"></i>
        </div>
      )}

      <CreateChannelModal
        showModal={showModal}
        toggleModal={toggleModal}
        listChannels={listChannels}
      />
      <TestChannelModal
        testChannel={testChannel}
        phone={phone}
        setPhone={setPhone}
        showTestChannelModal={showTestChannelModal}
        toggleTestChannelModal={toggleTestChannelModal}
        listChannels={listChannels}
      />
      <PrepModal
        toggleRequestModal={toggleRequestModal}
        show={showPrep}
        message={message}
        setMessage={setMessage}
        addItem={addItem}
        messages={messages}
        togglePrep={togglePrep}
        sequential={sequential}
        setCompanyKey={setCompanyKey}
        companyKey={companyKey}
      />
      <RequestModal
        show={showRequestModal}
        messages={messages}
        chKey={key}
        toggleRequestModal={toggleRequestModal}
        sessionIdRequest={sessionIdRequest}
        sequential={sequential}
        companyKey={companyKey}
      />
    </div>
  );
}

export default Channels;
