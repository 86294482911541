import React, { useEffect, useState } from "react";
import SideBar from "../component/SideBar";
import UserCard from "../component/UserCard";
import "../css/Users.css";
import UsersService from "../app/service/UsersService";
import CreateUserModal from "../component/CreateUserModal";

const Users = () => {
  const [users, setUsers] = useState([]);
  const service = new UsersService();

  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loginEdit, setLoginEdit] = useState("");

  const deleteUser = (userName) => {
    console.log(userName);
    service
      .deleteUser(userName)
      .then(() => listUsers())
      .catch();
  };

  const toggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  useEffect(() => {
    listUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listUsers = () => {
    service
      .listUsersAdmin("")
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the users!", error);
      });
  };

  return (
    <div id="user-wraper d-flex d-column">
      <SideBar active={"users"} />
      <div id="content">
        <div id="content-header">
          <h1>Usuários</h1>
        </div>
        <div className="d-flex justify-content-center">
          <div
            id="content-body"
            className="d-flex flex-wrap justify-content-left"
            style={{ gap: "40px", maxWidth: "1430px" }}
          >
            {users.map((user) => (
              <UserCard
                key={user.login}
                name={user.name}
                username={user.login}
                channel={user.channel ? "Vinculado" : "Não vinculado"}
                deleteUser={deleteUser}
                setEdit={setEdit}
                toggleModal={toggleModal}
                setLoginEdit={setLoginEdit}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        onClick={toggleModal}
        id="create-user"
        className="d-flex justify-content-center align-items-center"
      >
        <i className="fa-solid fa-plus"></i>
      </div>
      <CreateUserModal
        loginEdit={loginEdit}
        edit={edit}
        setEdit={setEdit}
        show={showModal}
        toggleModal={toggleModal}
        listUsers={listUsers}
      />
    </div>
  );
};

export default Users;
