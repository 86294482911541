import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

function ChangeBalanceModal({
  show,
  newBalance,
  setNewBalance,
  toggleModal,
  handleChangeBalance,
}) {
  return (
    <Modal show={show} onHide={toggleModal}>
      <Modal.Header closeButton>
        <h4>Saldo</h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            type="text"
            value={newBalance}
            onChange={(e) => setNewBalance(e.target.value)}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleChangeBalance()}>Salvar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ChangeBalanceModal;
