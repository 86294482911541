import React from "react";

function ChannelModal({ handleClose, show, qrCode, Modal, handleOpen }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      data-bs-theme="dark"
      onShow={handleOpen}
      className="modal-light"
    >
      <Modal.Header closeButton>
        <Modal.Title>QR Code</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center 
      align-content-center">
        {qrCode ? (
          <img src={qrCode} alt="QR Code" style={{ width: "100%" }} />
        ) : (
          <div className="loader"></div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ChannelModal;
