import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import "../css/CompanyModal.css";

function CompanyModal({
  show,
  toggleModal,
  setCreateCompanyData,
  createCompanyData,
  createCompany
}) {
  const handleClose = () => {
    toggleModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCreateCompanyData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  

  return (
    <Modal
      show={show}
      onHide={handleClose}
      data-bs-theme="dark"
      className="modal-light"
      size="lg"
    >
      <Modal.Header closeButton>
        <h5 style={{ margin: "0" }}>Empresa</h5>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col">
              <Form.Group controlId="formLegalName">
                <Form.Label>Razão social</Form.Label>
                <Form.Control
                  type="text"
                  name="legalName"
                  value={createCompanyData.legalName}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group controlId="formTradeName">
                <Form.Label>Nome fantasia</Form.Label>
                <Form.Control
                  type="text"
                  name="tradeName"
                  value={createCompanyData.tradeName}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group controlId="formCnpjCpf">
                <Form.Label>CPF/CNPJ</Form.Label>
                <Form.Control
                  type="text"
                  name="cnpjCpf"
                  value={createCompanyData.cnpjCpf}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group controlId="dazId">
                <Form.Label>Id Discador</Form.Label>
                <Form.Control
                  type="text"
                  name="dazId"
                  value={createCompanyData.dazId}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group controlId="formBalance">
                <Form.Label>Saldo inicial</Form.Label>
                <Form.Control
                  type="number"
                  name="balance"
                  value={createCompanyData.balance}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group controlId="formSendCost">
                <Form.Label>Custo disparo</Form.Label>
                <Form.Control
                  type="number"
                  name="sendCost"
                  value={createCompanyData.sendCost}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>
          <hr />
          <h5 style={{ margin: "0", marginBottom: "10px" }}>Administrador</h5>
          <div className="row">
          <Form.Group controlId="forName">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={createCompanyData.name}
                  onChange={handleChange}
                />
              </Form.Group>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group controlId="formLogin">
                <Form.Label>Login</Form.Label>
                <Form.Control
                  type="text"
                  name="login"
                  value={createCompanyData.login}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group controlId="formPassword">
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={createCompanyData.password}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            createCompany()
          }}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CompanyModal;
