import React, { useContext } from "react";
import { UserContext } from "../UserContext"; // ajuste o caminho conforme necessário

function SideBarMenu(props) {
  const { user } = useContext(UserContext); // Use the context

  if (!user) {
    return <div>Carregando...</div>; // Exibe uma mensagem ou spinner enquanto o usuário não está carregado
  }

  return (
    <ul className="nav nav-pills flex-column mb-auto">
      {(user.roles.includes("ADMIN") || user.roles.includes("MANAGER")) && (
        <li>
          <a
            href="/dashboard"
            className={`nav-link 
          ${props.active === "dashboard" ? "active" : ""}`}
          >
            <i
              className="fa-solid fa-chart-simple bi me-3"
              style={{ width: "16px", height: "16px", fontSize: "16px" }}
            ></i>
            Dashboard
          </a>
        </li>
      )}
      <li className="nav-item">
        <a
          href="/canais"
          className={`nav-link 
            ${props.active === "channels" ? "active" : ""}`}
        >
          <i
            className="fa-brands fa-whatsapp bi me-3"
            style={{ width: "20px", height: "20px", fontSize: "20px" }}
          ></i>
          {user.roles.includes("USER") ? "Meu Canal" : "Canais"}
        </a>
      </li>

      {(user.roles.includes("ADMIN") || user.roles.includes("MANAGER")) && (
        <li>
          <a
            href="/usuarios"
            className={`nav-link 
          ${props.active === "users" ? "active" : ""}`}
          >
            <i
              className="fa-solid fa-users bi me-3"
              style={{ width: "16px", height: "16px", fontSize: "16px" }}
            ></i>
            Usuários
          </a>
        </li>
      )}
    </ul>
  );
}

export default SideBarMenu;
