import React, { useEffect, useState } from "react";
import "../css/SubmissionsTable.css";
import DataTable from "react-data-table-component";

function SubmissionsTable({ submissions, filter, setFilter }) {
  const [search, setSearch] = useState("");
  const columns = [
    {
      name: "Data",
      selector: (row) => row.formattedDate,
      sortable: true,
    },
    {
      name: "Canal",
      selector: (row) => row.sessionId,
      sortable: true,
    },
    {
      name: "Custo",
      selector: (row) => row.sendCost,
      sortable: true,
    },
    {
      name: "Origem",
      selector: (row) => row.origin,
      sortable: true,
    },
    {
      name: "Destino",
      selector: (row) => row.destination,
      sortable: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Itens por página",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  useEffect(() => {
    const result = submissions.filter((item) => {
      const searchLower = search.toLowerCase();
      const sessionId = item.sessionId.toLowerCase();
      const date = item.formattedDate;
      const origin = item.origin;
      const destination = item.destination;

      return (
        sessionId.includes(searchLower) ||
        date.includes(search) ||
        origin.includes(search) ||
        destination.includes(search)
      );
    });

    setFilter(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const CustomNoDataComponent = () => (
    <div className="noData">
      <p>Nenhum dado encontrado!</p>
    </div>
  );

  return (
    <DataTable
      columns={columns}
      data={filter}
      pagination
      paginationComponentOptions={paginationComponentOptions}
      fixedHeader
      subHeader
      subHeaderComponent={
        <div className="table-header-container d-flex justify-content-between">
          <h4>Disparos</h4>
          <input
            type="text"
            className="form-control table-search"
            data-bs-theme="dark"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Pesquisar"
          />
        </div>
      }
      noDataComponent={<CustomNoDataComponent />}
    />
  );
}

export default SubmissionsTable;
